import React from 'react';
import './TextStyle.css';

const TextStyle = ({ children, type, weight }) => (
  <span className={`font-weight-${weight} font-${type}`}>{children}</span>
);

TextStyle.defaultProps = {
  weight: 'bold',
  type: ''
};

export default TextStyle;
