import React from 'react';
import Text from '../Text/Text';
import imgCheck from '../../assets/images/about/icon-check.png';
import './Item.css';

const Item = ({ text }) => (
  <div className="Item">
    <div>
      <img className="Item__img" alt="check" src={imgCheck} />
    </div>
    <Text className="paragraph3" text={text} />
  </div>
);

export default Item;
