import React from 'react';
import Responsive from 'react-responsive';
import Navbar from 'react-bootstrap/Navbar';
import Menu from '../Menu/Menu';
import logo from '../../assets/images/logo.png';
import logo2 from '../../assets/images/logo-md.png';
import './Header.css';

const Header = ({ style }) => (
  <Navbar
    as="header"
    collapseOnSelect
    expand="lg"
    style={style}
    className="Header align-items-center"
  >
    <Navbar.Brand href="/">
      <Responsive minWidth={576}>
        <img alt="Capital Motion" className="Header__logo" src={logo} />
      </Responsive>
      <Responsive maxWidth={575}>
        <img alt="Capital Motion" className="Header__logo" src={logo2} />
      </Responsive>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse
      id="responsive-navbar-nav"
      className="justify-content-lg-end"
    >
      <Menu />
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
