import React from 'react';
import './ImageTextItem.css';

const ImageTextItem = ({ alt, text, imagePath, imageStyle, className }) => (
  <div className="ImageTextItem">
    <div className="ImageTextItem__item">
      <img
        alt={alt}
        src={imagePath}
        style={imageStyle}
        className={`${className || ''} ImageTextItem__image`}
      />
    </div>
    <div className="ImageTextItem__item">
      <span className="ImageTextItem__text paragraph2">{text}</span>
    </div>
  </div>
);

export default ImageTextItem;
