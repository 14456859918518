import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../Text/Text';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import './ContactUs.css';

const ContactUs = ({ className, openOverlay }) => (
  <Container id="getInTouch" fluid className={`${className || ''} ContactUs`}>
    <Row className="wrapper App__section align-items-lg-center">
      <Col xs={12} md={4} lg={5}>
        <Text size="medium" hasLine>
          Drop us a line.
        </Text>
        <Text className="paragraph2 ContactUs__contactParagrahp">
          We can help you decide if
        </Text>
        <Text className="paragraph2">CapMotion is the solution for you.</Text>
      </Col>
      <Col xs={12} md={8} lg={7}>
        <ContactUsForm openOverlay={openOverlay} />
      </Col>
    </Row>
  </Container>
);

export default ContactUs;
