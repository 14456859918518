import React from 'react';
import TextStyle from '../TextStyle/TextStyle';
import './HexagonShape.css';

const HexagonShape = ({ title, text, type }) => (
  <div className={`HexagonShape HexagonShape__hexa${type}`}>
    <div className="HexagonShape__title">
      <TextStyle weight="bold">{title}</TextStyle>
    </div>
    <div className="HexagonShape__text">{text}</div>
  </div>
);

HexagonShape.defaultProps = {
  type: 1
};

export default HexagonShape;
