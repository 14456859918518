import React, { PureComponent } from 'react';
import { Nav } from 'react-bootstrap';
import './Menu.css';

class Menu extends PureComponent {
  static activeMenu(menu, selected) {
    return selected.indexOf(menu) >= 0 ? 'Menu_item--active' : '';
  }

  constructor(props) {
    super(props);
    this.state = {
      selected: window.document.location.hash
        ? window.document.location.hash
        : '#home'
    };
    this.toggleClick = this.toggleClick.bind(this);
  }

  toggleClick(event) {
    this.setState({
      selected: event.target.parentElement.hash
    });
  }

  render() {
    const { selected } = this.state;
    return (
      <>
        <Nav.Link
          className={`Menu_item ${this.constructor.activeMenu(
            '#home',
            selected
          )}`}
          href="#home"
          onClick={this.toggleClick}
        >
          <span>Home</span>
        </Nav.Link>
        <Nav.Link
          className={`Menu_item ${this.constructor.activeMenu(
            '#about',
            selected
          )}`}
          href="#about"
          onClick={this.toggleClick}
        >
          <span>About</span>
        </Nav.Link>
        <Nav.Link
          className={`Menu_item ${this.constructor.activeMenu(
            '#overview',
            selected
          )}`}
          href="#overview"
          onClick={this.toggleClick}
        >
          <span>Overview</span>
        </Nav.Link>
        <Nav.Link
          className={`Menu_item ${this.constructor.activeMenu(
            '#solutions',
            selected
          )}`}
          href="#solutions"
          onClick={this.toggleClick}
        >
          <span>Solutions</span>
        </Nav.Link>
        <Nav.Link
          className={`Menu_item ${this.constructor.activeMenu(
            '#getInTouch',
            selected
          )}`}
          href="#getInTouch"
          onClick={this.toggleClick}
        >
          <span>Get in touch</span>
        </Nav.Link>
      </>
    );
  }
}

export default Menu;
