import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Message.css';

const Message = ({ open, title, text, type, onClose }) => (
  <Modal show={open} onHide={onClose} className={`Message Message--${type}`}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{text}</Modal.Body>
    <Modal.Footer>
      <Button onClick={onClose}>Ok</Button>
    </Modal.Footer>
  </Modal>
);

export default Message;
