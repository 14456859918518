import React from 'react';
import TitleLine from '../TitleLine/TitleLine';
import './Text.css';

const Text = ({ size, text, children, hasLine, className, contentClass }) => {
  let element = null;
  switch (size) {
    case 'large':
      element = <h1 className={className}>{children || text}</h1>;
      break;
    case 'medium':
      element = <h2 className={className}>{children || text}</h2>;
      break;
    case 'small':
      element = <h3 className={className}>{children || text}</h3>;
      break;
    default:
      element = <p className={className}>{children || text}</p>;
  }
  return (
    <div className={contentClass}>
      {hasLine && <TitleLine />}
      {element}
    </div>
  );
};

Text.defaultProps = {
  hasLine: false
};

export default Text;
