import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../Text/Text';
import ImageTextItem from '../ImageTextItem/ImageTextItem';
import imgTechnology from '../../assets/images/home/icon-technology.png';
import imgExperience from '../../assets/images/home/icon-experience.png';
import imgFlexibility from '../../assets/images/home/icon-flexibility.png';
import imgSimplicity from '../../assets/images/home/icon-simplicity.png';
import './Home.css';

const Home = ({ className }) => (
  <Container id="home" className={`${className || ''} Home`} fluid>
    <Row className="Home_row wrapper">
      <Col>
        <div className="Home_title">
          <Text size="large" hasLine>
            A Fintech company{' '}
          </Text>
          <Text size="large">that enables innovation.</Text>
          <Text className="paragraph1 mt-3 mb-0 Home__titleParagraph">
            Complex trade finance technology solutions for banks and financial
            institutions.
          </Text>
        </div>
      </Col>
    </Row>
    <Row className="Home_why wrapper align-items-center">
      <Col lg={4} md={12} xs>
        <Text size="medium" hasLine>
          Why CapMotion?
        </Text>
      </Col>
      <Col lg={8} md={12} className="Home__whyImageList">
        <Row className="h-100 align-items-center">
          <Col lg={3} xs={6} md={3} sm={6}>
            <ImageTextItem
              alt="Technology"
              text="Next generation technology"
              imagePath={imgTechnology}
              className="Home_image"
            />
          </Col>
          <Col lg={3} xs={6} md={3} sm={6}>
            <ImageTextItem
              alt="Simplicity"
              text="Simplicity"
              imagePath={imgSimplicity}
              className="Home_image"
            />
          </Col>
          <Col lg={3} xs={6} md={3} sm={6}>
            <ImageTextItem
              alt="Flexibility"
              text="Flexibility"
              imagePath={imgFlexibility}
              className="Home_image"
            />
          </Col>
          <Col lg={3} xs={6} md={3} sm={6}>
            <ImageTextItem
              text="Insider Experience"
              imagePath={imgExperience}
              className="Home_image"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default Home;
