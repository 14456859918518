import React from 'react';
import Container from 'react-bootstrap/Container';
import MediaQuery from 'react-responsive';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../Text/Text';
import TextStyle from '../TextStyle/TextStyle';
import ImageTextItem from '../ImageTextItem/ImageTextItem';
import imgSoaComplete from '../../assets/images/solutions/soa-complete.png';
import imgSoa from '../../assets/images/solutions/soa-simple.png';
import imgInfraestructure from '../../assets/images/solutions/infraestructure.png';
import imgBlockchainComplete from '../../assets/images/solutions/blockchain-complete.png';
import imgAuthentication from '../../assets/images/solutions/authentication.png';
import imgMultiDevice from '../../assets/images/solutions/ multi-device.png';
import imgTradeCustom from '../../assets/images/solutions/trade-customization.png';
import imgReporting from '../../assets/images/solutions/reporting.png';
import imgTransaction from '../../assets/images/solutions/transaction.png';
import imgNetwork from '../../assets/images/solutions/network.png';
import imgNodes from '../../assets/images/solutions/nodes.png';
import imgBlock from '../../assets/images/solutions/blocks.png';
import imgSuccess from '../../assets/images/solutions/success.png';
import './Solutions.css';

const Solutions = ({ className }) => (
  <Container id="solutions" fluid className={`${className || ''} Solutions`}>
    <Row className="wrapper App__section align-items-center">
      <Col xs={12} md={6} lg={6}>
        <div>
          <Text size="medium" hasLine>
            A platform designed
          </Text>
          <Text size="medium">to meet your needs</Text>
          <Text className="paragraph2 Solutions_platformParagraph">
            CapMotion uses a{' '}
            <TextStyle weight="bold">
              Service Oriented Architecture (SOA).{' '}
            </TextStyle>
            <Text className="paragraph2  mt-4  Solutions_platformParagraph2">
              SOA is a structure that allows services to communicate with each
              other across different platforms, allowing for:
            </Text>
          </Text>
          <Text className="paragraph2 mt-4 mb-2">
            - Efficiency in developing, texting and fixing
          </Text>
          <Text className="paragraph2 mb-2">
            - Develop new features at a lower cost
          </Text>
          <Text className="paragraph2">- Performance at faster speeds</Text>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={6}
        className="d-md-flex justify-content-md-center align-items-md-center"
      >
        <MediaQuery minWidth={992}>
          <img
            alt="Solutions"
            className="Solutions__img"
            src={imgSoaComplete}
          />
        </MediaQuery>
        <MediaQuery maxWidth={991}>
          <div className="d-flex  align-items-center justify-content-center mt-3">
            <img alt="Solutions" className="Solutions__img_soa" src={imgSoa} />
          </div>
        </MediaQuery>
      </Col>
    </Row>
    <Row className="wrapper App__section  align-items-center">
      <Col xs={12} md={6} lg={6}>
        <div>
          <Text size="medium" hasLine>
            We know your
          </Text>
          <Text size="medium">time is valuable.</Text>
          <Text className="paragraph2 mt-5 Solutions__valuableParagraph">
            CapMotion ensures a streamlined process through an{' '}
            <TextStyle weight="bold">infrastructure </TextStyle>
            that provides a reliable and efficient service.
          </Text>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={6}
        className="d-flex justify-content-center align-items-center"
      >
        <img
          alt="Solutions"
          className="Solutions__img Solutions__img_infraestructure"
          src={imgInfraestructure}
        />
      </Col>
    </Row>
    <Row className="wrapper App__section align-items-center">
      <Col xs={12} md lg={4}>
        <div>
          <Text size="medium" hasLine>
            We’ve got you
          </Text>
          <Text size="medium">covered</Text>
          <Text className="paragraph2 Solutions__coveredParagraph">
            CM utilizes <TextStyle weight="bold">Blockchain</TextStyle>, which
            is a database that can store any kind of data with the help of
            distributed processing nodes.
          </Text>
          <Text className="paragraph2 mt-3 Solutions__coveredParagraph">
            The database can be found in each of the nodes, which then establish
            a consensus among each other.
          </Text>
          <Text className="paragraph2 mt-3 Solutions__coveredParagraph">
            With Blockchain,{' '}
            <TextStyle weight="bold">
              you can rely on the integrity of the database.
            </TextStyle>
          </Text>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={8}
        className="d-md-flex justify-content-md-center align-items-md-center"
      >
        <MediaQuery minWidth={576}>
          <img
            alt="Solutions"
            className="Solutions__img"
            src={imgBlockchainComplete}
          />
        </MediaQuery>
        <MediaQuery maxWidth={575}>
          <Row className="justify-content-center">
            <Col xs={6}>
              <ImageTextItem
                alt="Transaction"
                text="1. Seller discounts receivables"
                imagePath={imgTransaction}
                className="Home_image"
              />
            </Col>
            <Col xs={6}>
              <ImageTextItem
                alt="Network"
                text="2. Transaction goes through node network"
                imagePath={imgNetwork}
                className="Home_image"
              />
            </Col>
            <Col xs={6}>
              <ImageTextItem
                alt="Validate"
                text="3. Nodes Validate transaction"
                imagePath={imgNodes}
                className="Home_image"
              />
            </Col>
            <Col xs={6}>
              <ImageTextItem
                alt="Add Block"
                text="4. New block added to the chain"
                imagePath={imgBlock}
                className="Home_image"
              />
            </Col>
            <Col xs={6}>
              <ImageTextItem
                alt="Success"
                text="5. Transaction is completed"
                imagePath={imgSuccess}
                className="Home_image"
              />
            </Col>
          </Row>
        </MediaQuery>
      </Col>
    </Row>
    <Row className="wrapper App__section align-items-center">
      <Col xs={12} md={6} lg={6}>
        <div>
          <Text size="medium" hasLine>
            Protecting your
          </Text>
          <Text size="medium">privacy.</Text>
          <Text className="paragraph2 Solutions__protectingParagrahp">
            CMT provides safe accessibility using{' '}
            <TextStyle weight="bold">Factor Authentication.</TextStyle>
          </Text>
          <Text className="paragraph2 Solutions__protectingParagrahp">
            This method of confirming a user’s identity protects the user’s
            information against unauthorized access.
          </Text>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={6}
        className="d-flex justify-content-center align-items-center"
      >
        <img
          alt="Solutions"
          className="Solutions__img Solutions__img_authentication"
          src={imgAuthentication}
        />
      </Col>
    </Row>
    <Row className="wrapper App__section align-items-center">
      <Col xs={12} md={6} lg={6}>
        <div>
          <Text size="medium" hasLine>
            Business
          </Text>
          <Text size="medium">on-the-go.</Text>
          <Text className="paragraph2 Solutions__businessParagrahp">
            Productivity is not constrained to one medium. Our platform allows
            <TextStyle weight="bold"> Multi-device Accessibility</TextStyle> for
            your convenience.
          </Text>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={6}
        className="d-flex justify-content-center align-items-center "
      >
        <img
          alt="Solutions"
          className="Solutions__img Solutions__img_business"
          src={imgMultiDevice}
        />
      </Col>
    </Row>
    <Row className="wrapper App__section align-items-center">
      <Col xs={12} md={6} lg={6}>
        <div>
          <Text size="medium" hasLine>
            Technology
          </Text>
          <Text size="medium">fit for every profile.</Text>
          <Text className="paragraph2 Solutions__technologyParagraph">
            <TextStyle weight="bold">Trade customization</TextStyle> gives our
            platform the flexibility to behave according to the intended funding
            scenario.
          </Text>
          <Text className="paragraph2 Solutions__technologyParagraph2">
            This allows for the occurrence of
            <TextStyle weight="bold"> multiple types of trades</TextStyle> that
            have very different characteristics.
          </Text>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={6}
        className="d-md-flex justify-content-md-center align-items-md-center"
      >
        <img
          alt="Solutions"
          className="Solutions__img Solutions__img_trade"
          src={imgTradeCustom}
        />
      </Col>
    </Row>
    <Row className="wrapper App__section align-items-center">
      <Col xs={12} md={6} lg={6}>
        <div>
          <Text size="medium" hasLine>
            Keep track of
          </Text>
          <Text size="medium">transactions.</Text>
          <Text className="paragraph2 Solutions__keepParagraph">
            The CMT platform generates{' '}
            <TextStyle weight="bold">reports</TextStyle> to fit your needs.
          </Text>
          <Text className="paragraph2 Solutions__keepParagraph2">
            We have the ability to extract data to send to data visualization
            tools such as Tableau or MS Power BI.
          </Text>
          <Text className="paragraph4 Solutions__keepParagraph3">
            Some of the default platform reports are:
          </Text>
          <ul className="paragraph4 mt-1">
            <li className="mb-1">Aging Report</li>
            <li className="mb-1">
              Transactions based on Buyer / Seller / Type of Funding / Currency
            </li>
            <li className="mb-1">Capacity Report</li>
            <li className="mb-1">Payment Report</li>
            <li className="mb-1">Buyer / Seller Activity Report</li>
          </ul>
        </div>
      </Col>
      <Col
        xs={12}
        md={6}
        lg={6}
        className="d-md-flex justify-content-md-center align-items-md-center"
      >
        <img alt="Solutions" className="Solutions__img" src={imgReporting} />
      </Col>
    </Row>
  </Container>
);

export default Solutions;
