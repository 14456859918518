import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../Text/Text';
import TextStyle from '../TextStyle/TextStyle';
import imgCali from '../../assets/images/about/cali.png';
import imgAtlanta from '../../assets/images/about/atlanta.png';
import imgMonterrey from '../../assets/images/about/monterrey.png';
import './About.css';
import Item from '../Item/Item';

const About = ({ className }) => (
  <Container id="about" className={`${className || ''} About`} fluid>
    <Row className="App__section About_info wrapper align-items-center">
      <Col xs={12} md={4} lg={4}>
        <div className="About_info__title">
          <Text size="medium" hasLine>
            Who are we?
          </Text>
        </div>
        <Text className="paragraph2">
          <TextStyle weight="bold">CapMotion</TextStyle> is a FinTech company
          that focuses on the design, development, and deployment of complex
          trade finance technology solutions for banks and financial
          institutions.
        </Text>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <div className="About_info__title">
          <Text size="medium" hasLine>
            Who is it for?
          </Text>
        </div>
        <Text className="paragraph2">
          <TextStyle weight="bold">Banks</TextStyle> and{' '}
          <TextStyle weight="bold">financial institutions</TextStyle> that want
          to improve how they finance their clients.
        </Text>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <div className="About_info__title">
          <Text size="medium" hasLine>
            What can we do for you?
          </Text>
        </div>
        <Text className="paragraph2">
          We help you <TextStyle weight="bold">develop</TextStyle> the next
          generation of working capital product offerings for your clients.
        </Text>
      </Col>
    </Row>
    <Row className="App__section wrapper align-items-center">
      <Col>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <div className="About__mision">
              <Text size="medium" hasLine>
                Mission
              </Text>
              <Text className="paragraph2">
                To act as an <TextStyle weight="bold">enabler</TextStyle> for
                financial institutions to provide simplified and reliable
                automated transactions between buyers and suppliers using the
                latest technology.
              </Text>
            </div>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="About__vision">
              <Text size="medium" hasLine>
                Vision
              </Text>
              <Text className="paragraph2">
                To <TextStyle weight="bold">enhance</TextStyle> the trade
                finance experience and become the leader in FinTech solutions,
                unlocking economic possibilities that benefit all entities
                involved in the process.
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="About__location">
          <Col className="About__locationTitle">
            <Text size="medium" hasLine>
              Location
            </Text>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={4} className="About__locationCity">
            <div>
              <img
                alt="Atlanta"
                src={imgAtlanta}
                className="About__cityImage"
              />
            </div>
            <div className="About_cityName">
              <Text size="small">ATLANTA, GA, USA</Text>
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} className="About__locationCity">
            <div>
              <img
                alt="Monterry"
                src={imgMonterrey}
                className="About__cityImage"
              />
            </div>
            <div className="About_cityName">
              <Text size="small">MONTERREY, NL, MEXICO</Text>
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} className="About__locationCity">
            <div>
              <img alt="Cali" src={imgCali} className="About__cityImage" />
            </div>
            <div className="About_cityName">
              <Text size="small">CALI, VAC, COLOMBIA</Text>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="App__section wrapper align-items-center">
      <Col>
        <Row>
          <Col lg={6} md={4}>
            <Text size="medium" hasLine>
              Industry experience
            </Text>
            <Text size="medium">and a drive to innovate.</Text>
            <Text className="paragraph2 mt-5 About__industry_paragraph">
              Capmotion&apos;s leadership team brings together
              <TextStyle weight="bold"> seasoned experts</TextStyle> from{' '}
              <TextStyle weight="bold">diverse areas</TextStyle> to provide
              unique insights for successful innovation.
            </Text>
          </Col>
          <Col lg={6} md={8}>
            <Text size="small" className="About__listTitle">
              AREAS OF EXPERTISE:
            </Text>
            <div className="About__list">
              <div>
                <Item text="Accounts Receivable Finance" />
                <Item text="Payables Extension Finance" />
                <Item text="Supply Chain Finance" />
                <Item text="Product Strategy" />
                <Item text="Product Development & Design" />
                <Item text="Platform Architecture" />
              </div>
              <div>
                <Item text="UX/UI Design" />
                <Item text="Blockchain Connectivity" />
                <Item text="Bank-grade Infrastructure & security" />
                <Item text="Applied Artificial Intelligence (AII)" />
                <Item text="Business Intelligence & Analytics" />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default About;
