import React, { Component } from 'react';
import './Recaptcha.css';

class Recaptcha extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    const { className, siteKey } = this.props;
    return (
      <div
        className={`${className || ''} g-recaptcha`}
        data-sitekey={siteKey}
      />
    );
  }
}

export default Recaptcha;
