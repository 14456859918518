import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonOutline from '../ButtonOutline/ButtonOutline';
import Message from '../Message/Message';
import Recaptcha from '../Recaptcha/Recaptcha';
import './ContactUsForm.css';

class ContactUsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      messageType: 'success',
      messageText: '',
      messageTitle: 'Successful',
      validated: false,
      siteKey: ''
    };
    this.onChange = this.onChange.bind(this);
    this.toggleMessage = this.toggleMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.getSiteKey = this.getSiteKey.bind(this);
  }

  componentDidMount() {
    this.getSiteKey();
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity()) {
      const { openOverlay } = this.props;
      openOverlay(true);
      this.sendEmail(form).then(({ success, message }) => {
        openOverlay(false);
        this.setState({
          showMessage: true,
          messageType: success ? 'success' : 'error',
          messageText: success
            ? 'The form has been sent successfully'
            : message,
          messageTitle: 'Alert'
        });

        if (success) {
          form.reset();
        }
      });
    } else {
      this.setState({
        showMessage: true,
        messageType: 'warning',
        messageText: 'Please, fill in all red fields',
        messageTitle: 'Alert'
      });
    }
    this.setState({ validated: true });
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async getSiteKey() {
    const response = await fetch('/api/configurations/siteKey');
    const { success, data: { siteKey = '' } = {} } = await response.json();

    if (success) {
      this.setState({
        siteKey
      });
    } else {
      window.console.error("Site key doesn't exist, please check.");
    }
  }

  async sendEmail(form) {
    try {
      const formData = new FormData(form);
      const response = await fetch('/api/contact/sendEmail', {
        method: 'POST',
        cache: 'no-cache',
        headers: {},
        body: formData
      });
      const data = await response.json();
      this.setState({ validated: true });
      return data;
    } catch (e) {
      return {
        success: false,
        error: e.toString()
      };
    }
  }

  toggleMessage() {
    const { showMessage } = this.state;
    this.setState({
      showMessage: !showMessage
    });
  }

  render() {
    const {
      showMessage,
      messageType,
      messageText,
      messageTitle,
      validated,
      siteKey
    } = this.state;
    // const siteKey = '6LcKtdEdAAAAAKv8dRgC-ri1lAOi4A8EIQoJMI_t';

    return (
      <section className="ContactUsForm">
        <Form
          className="ContactUsForm__form paragraph4"
          noValidate
          validated={validated}
          onSubmit={event => this.handleSubmit(event)}
        >
          <Form.Group className="ContactUsForm__row" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <InputGroup>
              <Form.Control
                type="email"
                name="email"
                onChange={event => {
                  this.onChange(event);
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="ContactUsForm__row" controlId="formBasicEmail">
            <Form.Label>First Name</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="firstName"
                onChange={event => {
                  this.onChange(event);
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="ContactUsForm__row" controlId="formBasicEmail">
            <Form.Label>Last Name</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="lastName"
                onChange={event => {
                  this.onChange(event);
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="ContactUsForm__row" controlId="formBasicEmail">
            <Form.Label>Message</Form.Label>
            <InputGroup>
              <Form.Control
                as="textarea"
                rows="3"
                name="contactMessage"
                onChange={event => {
                  this.onChange(event);
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                Is required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Row className="mt-2">
            <Col xl={7}>{siteKey ? <Recaptcha siteKey={siteKey} /> : ''}</Col>
            <Col xl={5} className="d-flex align-items-center">
              <ButtonOutline
                type="submit"
                className="ContactUsForm__button mt-2"
              >
                SUBMIT
              </ButtonOutline>
            </Col>
          </Row>
          <div className="paragrahp4 text-right mt-4">
            Powered by Capital Motion Technologies LLC. 2021
          </div>
        </Form>
        <Message
          open={showMessage}
          type={messageType}
          text={messageText}
          title={messageTitle}
          onClose={this.toggleMessage}
        />
      </section>
    );
  }
}

export default ContactUsForm;
