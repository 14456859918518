import React from 'react';
import './Overlay.css';
import Spinner from 'react-bootstrap/Spinner';

const Overlay = ({ show }) =>
  show ? (
    <div className="OverLay-loading">
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
        <span className="Overlay-loading__text">Sending...</span>
      </div>
    </div>
  ) : (
    ''
  );

export default Overlay;
