import React, { useState } from 'react';
import { ScrollTo } from 'react-scroll-to';
import Container from 'react-bootstrap/Container';
import Home from '../Home/Home';
import Header from '../Header/Header';
import Overview from '../Overview/Overview';
import Solutions from '../Solutions/Solutions';
import ContactUs from '../ContactUs/ContactUs';
import About from '../About/About';
import Overlay from '../Overlay/Overlay';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function App() {
  const [open, setOpen] = useState(false);
  return (
    <div className="App">
      <Overlay show={open} />
      <ScrollTo>
        {({ scrollTo }) => (
          <>
            <Header />
            <Container className="App__wrapperPages" fluid>
              <Home className="App__pages" />
              <About className="App__pages" />
              <Overview className="App__pages" />
              <Solutions className="App__pages" />
              <ContactUs
                className="App__pages"
                scrollTo={scrollTo}
                openOverlay={setOpen}
              />
            </Container>
          </>
        )}
      </ScrollTo>
    </div>
  );
}

export default App;
