import React from 'react';
import MediaQuery from 'react-responsive';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../Text/Text';
import TextStyle from '../TextStyle/TextStyle';
import HexagonShape from '../HexagonShape/HexagonShape';
import imgSolutions from '../../assets/images/overview/solutions.png';
import imgFundings from '../../assets/images/overview/fundings.png';
import './Overview.css';

const Overview = props => {
  const { className } = props;
  return (
    <Container id="overview" fluid className={`${className || ''} Overview`}>
      <Row className="App__section wrapper align-items-lg-center">
        <Col xs={12} md={6} lg={6}>
          <div>
            <Text size="medium" hasLine>
              Unlocking economic
            </Text>
            <Text size="medium">possibilities.</Text>
            <Text className="paragraph2 Overview__unlockingParagraph">
              Our flexible, multi-faceted technology allows financial
              institutions to provide their clients with the{' '}
              <TextStyle weight="bold">perfect tool</TextStyle> for simplified
              and reliable transactions.
            </Text>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={6}
          className="d-flex justify-content-center align-items-center"
        >
          <img alt="Solutions" className="Overview_img" src={imgSolutions} />
        </Col>
      </Row>
      <Row className="App__section wrapper  align-items-lg-center flex-column">
        <Col>
          <Text size="medium" hasLine>
            Working capital solutions
          </Text>
          <Text size="medium">to help your clients.</Text>
          <MediaQuery minWidth={768}>
            <div className="d-flex  align-items-center justify-content-center">
              <img alt="Fundings" src={imgFundings} className="Overview_img" />
            </div>
          </MediaQuery>
        </Col>
        <MediaQuery maxWidth={767}>
          <Col>
            <Row className="Overview__HexagonShape">
              <Col xs={12} sm={6}>
                <HexagonShape
                  type={4}
                  title="FACTORING"
                  text="Program enables companies to sell their receivables at a discount in order to reduce DSO."
                />
              </Col>
              <Col xs={12} sm={6}>
                <HexagonShape
                  type={1}
                  title="PAYABLES FINANCE"
                  text="Program allows for buyers to also finance their payables and obtain extended terms."
                />
              </Col>
              <Col xs={12} sm={6}>
                <HexagonShape
                  type={2}
                  title="REVERSE FACTORING"
                  text="Program allows a Bank/FI to deploy capital to the sellers of a specific buyer, under the risk of the buyer."
                />
              </Col>
              <Col xs={12} sm={6}>
                <HexagonShape
                  type={3}
                  title="DYNAMIC DISCOUNTING"
                  text="Program allows the buyer to fund its own receivables, while acting as a funder to generate significant discounts."
                />
              </Col>
            </Row>
          </Col>
        </MediaQuery>
      </Row>
    </Container>
  );
};

export default Overview;
