import React from 'react';
import Button from 'react-bootstrap/Button';
import './ButtonOutline.css';

const ButtonOutline = ({ type, className, children, onClick }) => (
  <Button
    type={type}
    onClick={onClick}
    className={`${className || ''} ButtonOutline`}
  >
    {children}
  </Button>
);

export default ButtonOutline;
